<template>
  <layout :title="$route.name" :options="options">
    <div style="min-height: 80vh" class="pa-2">
      <v-app-bar
        v-if="item_selecionado"
        class="elevation-1"
        color="#333"
        app
        dense
        clipped-left
        dark
      >
        <div class="expande-horizontal centraliza">
          <v-btn
            class="animated fadeInUp elevation-0"
            hover
            small
            :color="$theme.primary"
          >
            <span :style="`color: white`">
              EXCLUIR {{ contagem_selecionados }} SELECIONADO(S)
            </span>
          </v-btn>
        </div>
      </v-app-bar>

      <div style="flex-wrap: wrap;" class="expande-horizontal pa-1 coluna">
        <h3 class="fonte pa-2">Filtros:</h3>
        <div style="flex-wrap: wrap;" class="expande-horizontal">
          <v-flex class="px-1" xs6 md2>
            <v-select
              :items="['SP', 'AP']"
              label="Estado"
              dense
              outlined
              v-model="estado"
            ></v-select>
          </v-flex>
          <v-flex class="px-1" xs6 md2>
            <v-text-field
              label="Cidade"
              dense
              outlined
              v-model="cidade"
            ></v-text-field>
          </v-flex>
          <v-flex class="px-1" xs6 md1>
            <v-btn
              block
              class="fonte"
              dark
              :color="$theme.primary"
              @click="
                listarLocalidades({
                  case: 'listarPorEstado',
                  payload: { estado, cidade },
                  page: 1
                })
              "
            >
              Buscar
              <v-icon class="ml-1" size="18">mdi-magnify</v-icon>
            </v-btn>
          </v-flex>
          <v-flex class="px-1" xs6 md1>
            <v-btn
              text
              block
              class="fonte"
              dark
              :color="$theme.primary"
              @click="listarLocalidades()"
            >
              Zerar Filtro
              <v-icon class="ml-1" size="18">mdi-magnify</v-icon>
            </v-btn>
          </v-flex>
        </div>
      </div>

      <h3 class="fonte pa-3 pb-1">
        Resultados: {{ getLocalidades.totalDocs }}
      </h3>
      <v-simple-table
        dense
        class="item-table ma-2"
        v-if="getLocalidades.docs.length > 0 && !$vuetify.breakpoint.smAndDown"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>LOCALIDADE</th>
              <th>LATITUDE</th>
              <th>LONGITUDE</th>
              <th>ESTADO</th>
              <th>CÓDIGO</th>
              <th></th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in getLocalidades.docs"
              :key="item._id"
              class="animated fadeInUp item-table"
              :class="index % 2 === 0 ? 'bg-table' : ''"
            >
              <!-- <td @input="verificaSelecionados($event)" style="width: 20px;" :class="item.selected ? 'bg-selected' : ''">
                            <input type="checkbox" x-small color="white" v-model="getLocalidades[index].selected" />
                        </td> -->

              <td class="font-weight-bold fonte">{{ item.cidade }}</td>
              <td class="fonteCorpo">{{ item.latitude }}</td>
              <td class="fonteCorpo">{{ item.longitude }}</td>
              <td class="fonteCorpo">{{ item.estado }}</td>
              <td class="fonteCorpo">{{ item.codigo }}</td>
              <td>
                <v-btn
                  small
                  outlined
                  class="text-lowercase"
                  :color="$theme.primary"
                  @click="visualizarLocalidade(item)"
                >
                  visualizar</v-btn
                >
              </td>
              <!-- <td> <v-btn small outlined class="text-lowercase" @click.prevent="remover(item._id, false)"> Excluir</v-btn> </td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <Empty v-if="getLocalidades.docs.length === 0"></Empty>

      <v-list
        class="pa-0 ma-0"
        v-if="getLocalidades.docs.length > 0 && $vuetify.breakpoint.smAndDown"
      >
        <template v-for="(item, index) in getLocalidades.docs">
          <v-list-item
            class="animated fadeInUp"
            @click="visualizarLocalidade(item)"
            :key="item._id"
          >
            <v-list-item-content>
              <v-list-item-title
                class="fonte font-weight-bold"
                style="color: #444"
              >
                {{ item.cidade }} - {{ item.estado }}</v-list-item-title
              >
              <v-list-item-subtitle class="fonte">
                CÓD: {{ item.codigo }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$theme.primary">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>

      <div class="expande-horizontal centraliza">
        <v-pagination
          total-visible="8"
          :color="$theme.primary"
          v-model="page"
          :length="getLocalidades.totalPages"
          @input="setPage($event)"
        ></v-pagination>
      </div>

      <div style="display: flex; height: 100vh">
        <MglMap
          zoom="3"
          :center="center"
          style="background-color: #555"
          :accessToken="accessToken"
          :mapStyle.sync="mapStyle"
        >
          <div v-for="item in getLocalidades.docs" :key="item._id">
            <MglMarker
              :coordinates="[item.longitude, item.latitude]"
              color="#F9A825"
            >
              <MglPopup
                :coordinates="[item.latitude, item.longitude]"
                anchor="top"
              >
                <div>
                  <h3 class="fonte font-weight-bold">
                    {{ item.cidade }} - {{ item.estado }}
                  </h3>
                  <h5
                    :key="irradiacao"
                    v-for="(irradiacao, i) in item.irradiacao_global_horizontal"
                  >
                    {{ i === 0 ? "Anual: " : `Mês ${i}: ` }} {{ irradiacao }}
                  </h5>
                  <v-btn
                    block
                    class="white--text"
                    :color="$theme.primary"
                    x-small
                    @click="visualizarLocalidade(item)"
                    >ver</v-btn
                  >
                </div>
              </MglPopup>
            </MglMarker>
          </div>
        </MglMap>
      </div>

      <!-- <Confirm :open="confirm_dialog" :action="() => this.remover(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" /> -->
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglPopup } from "vue-mapbox";

export default {
  components: {
    MglMap,
    MglMarker,
    MglPopup
  },
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoicHRrbG9ycmFuIiwiYSI6ImNrM2JzbXRqZTBnZjkzbnFlM3VyYTVzazkifQ.kgDrdfqGfd6XTV5DXfeSwg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/dark-v10", // your map style
      mapbox: Mapbox,
      center: [-50.5, -20],
      page: 1,
      pesquisa: "",
      options: [],
      estado: "",
      cidade: "",
      confirm_dialog: false,
      item_selecionado: false,
      contagem_selecionados: 0,
      id_to_excluse: "",
      options: [
        {
          nome: "Cadastrar",
          action: () => this.setModalFormLocalidade(true)
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getLocalidades", "getLocalidade"])
  },
  methods: {
    ...mapActions([
      "listarLocalidades",
      "setModalFormLocalidade",
      "visualizarLocalidade"
    ]),
    setPage(page) {
      this.page = page;
      this.listarLocalidades({
        case: "listarTodas",
        page: parseInt(page),
        payload: {}
      });
    }
  },
  created() {
    this.listarLocalidades();
  }
};
</script>
